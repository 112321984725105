import styles from "./header.styles.module.css";
import CGAI_LETRAS_MORADAS from "../../../../assets/images/CGAI_LETRAS_MORADAS.png";
import UDG_LOGO_COLOREADO from "../../../../assets/images/UDG_LOGO_COLOREADO.png";
// import { CiUser } from "react-icons/ci";
import HeaderUser from "./HeaderUser";

export const Header = () => {
  return (
    <header className={styles.header}>
      <div className={styles.logo_container}>
        {/* <img src={logo_udg} alt="logo UDG" className={styles.udg_logo} /> */}

        <div className={styles.udg_title}>
          <img
            src={UDG_LOGO_COLOREADO}
            alt="logo UDG"
            className={styles.udg_logo}
          />
          <span>
            <p>UNIVERSIDAD DE</p>
            <p className={styles.udg_title_guadalajara}>GUADALAJARA</p>
          </span>
        </div>
      </div>
      <div className={styles.logo_container}>
        <img
          src={CGAI_LETRAS_MORADAS}
          alt="logo CGAI"
          className={styles.CGAI_logo}
        />
      </div>

      <HeaderUser />
    </header>
  );
};
