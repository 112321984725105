import React, { useMemo, useState } from "react";
import styles from "./Menu.module.css";

export const MenuOption = ({ isSelected, onSelect, children }) => {
  return (
    <div
      className={`${styles.menuOption} ${isSelected ? styles.selected : ""}`}
      onClick={onSelect}
    >
      {children}
    </div>
  );
};

export const Menu = ({ menu }) => {
  return (
    <div
      className={`${styles.options} ${menu.isVertical ? styles.vertical : ""}`}
    >
      {menu.elements}
    </div>
  );
};

export const useMenu = (
  options,
  {
    selectedValue: initialSelectedValue = options[0].label, //* Se asigna el valor de la primera opción como valor inicial
    isVertical = false,
  } = {}
) => {
  const [selectedValue, setSelectedValue] = useState(initialSelectedValue);

  const handleOptionClick = (value) => {
    setSelectedValue(value);
  };

  const elements = useMemo(() => {
    return options.map((option) => {
      return {
        option: (
          <MenuOption
            key={option.label}
            isSelected={option.label === selectedValue}
            onSelect={() => handleOptionClick(option.label)}
          >
            {option.label}
          </MenuOption>
        ),
        label: option.label,
        element: option.element,
      };
    });
  }, [options, selectedValue]);

  const element = useMemo(() => {
    return elements.find((option) => option.label === selectedValue)?.element;
  }, [selectedValue, elements]);

  return {
    elements: elements.map((option) => option.option),
    element,
    isVertical,
  };
};
