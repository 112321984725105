import { Footer, Header, SiderBarComponent } from "../../layout/components";
import { LlenarSolicitudComponent } from "../components/LlenarSolicitudComponent";
import styles from "./llenarSolicitud.module.css";

export const LlenarSolicitudPage = () => {
  // const data = [
  //   "React",
  //   "Angular",
  //   "Vue",
  //   "Svelte",
  //   "Node.js",
  //   "Express",
  //   "JavaScript",
  //   "TypeScript",
  //   "HTML",
  //   "CSS",
  // ];
  return (
    <>
      <div className={styles.solicitudContainer}>
        {/* <SiderBarComponent data={data} /> */}
        <LlenarSolicitudComponent />
      </div>
    </>
  );
};
