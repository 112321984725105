import { Footer, Header } from "../../layout/components";
import { BuscarUsuario, NavButtons } from "../components";
import styles from './inconformidad.module.css'

export const InconformidadPage = () => {
  return (
    <>
      <div className={styles.inconformidad_container}>
        <BuscarUsuario />
        <NavButtons />
      </div>
    </>
  );
};
