import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { LoginPage } from "../features/layout";
import { InstruccionesPage } from "../features/instrucciones";
import { InconformidadPage } from "../features/inconformidad";
import { JefeDepartamentoPage } from "../features/jefe_departamento";
import { LlenarSolicitudPage } from "../features/llenarSolicitud/pages/LlenarSolicitudPage";
import { AdministracionPage } from "../features/administracion/pages/AdministracionPage";

export const Navigation = () => {
  return (
    <>
      <Routes>
      
        <Route path="/login" element={<LoginPage />} />
        <Route path="/instrucciones" element={<InstruccionesPage />} />
        <Route path="/inconformidad" element={<InconformidadPage />} />
        <Route path="/jefe_departamento" element={<JefeDepartamentoPage />} />
        <Route path="/administracion" element={<AdministracionPage />} />
        <Route path="/llenar-solicitud" element={<LlenarSolicitudPage />} />
        {/* Ruta por defecto */}
        <Route path="*" element={<Navigate to="/login" replace />} />
      </Routes>
    </>
  );
};
