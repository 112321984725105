import { useState } from "react";
import styles from "./navButtons.module.css";
import { Requisitos } from "./Requisitos";

export const NavButtons = () => {
  const [option, setOption] = useState("datos");

  const handleClick = (optionSelected) => () => {
    setOption(optionSelected);
    console.log(optionSelected);
  };

  return (
    <div className={styles.container}>
      <div className={styles.buttons_container}>
        <button onClick={handleClick("datos")} className={styles.button}>
          Datos del participante
        </button>
        <button onClick={handleClick("requisitos")} className={styles.button}>
          Requisitos
        </button>
        <button onClick={handleClick("evaluar")} className={styles.button}>
          Evaluar
        </button>
        <button onClick={handleClick("notas")} className={styles.button}>
          Notas
        </button>
      </div>
      <div>
        {option === "datos" && <div>Datos del participante content</div>}
        {option === "requisitos" && <Requisitos />}
        {option === "evaluar" && <div>Evaluar content</div>}
        {option === "notas" && <div>Notas content</div>}
      </div>
    </div>
  );
};
