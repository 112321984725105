import React from "react";
import { Footer, Header } from "../../layout";
import styles from "./styles/AdminisstracionPage.module.css";
import { Menu, MenuOption, useMenu } from "../../../reutilizable/Menu";
import { GenerarInconformidad } from "../components/GenerarInconformidad";
import { Evaluar } from "../components/Evaluar";
import { BuscarUsuario } from "../../inconformidad/components";
import { InconformidadPage } from "../../inconformidad";
import { LlenarSolicitudPage } from "../../llenarSolicitud/pages/LlenarSolicitudPage";
import { useAuthStore } from "../../../store/useAuthStore"; 

const data = [
  {
    id: 1,
    nombre: "Maria",
    apellido: "Gonzalez",
    email: "",
    telefono: "",
  },
  {
    id: 2,
    nombre: "Juan",
    apellido: "Perez",
    email: "",
    telefono: "",
  },
  {
    id: 3,
    nombre: "Pedro",
    apellido: "Perez",
    email: "",
    telefono: "",
  },
  {
    id: 4,
    nombre: "Alejandro Sebastian",
    apellido: "Lopez de la Cruz",
    email: "",
    telefono: "",
  },
];
export const AdministracionPage = () => {
  const accessToken = useAuthStore((state) => state.accessToken);
  const userInfo = useAuthStore((state) => state.userInfo);
  const menu = useMenu(
    [
      {
        label: "Pagina Principañ",
        element: (
          <>
            <p>Pagina principal</p>,
          </>
        ),
      },
      {
        label: "Programas",
        element: <p>Programas</p>,
      },
      {
        label: "Inconformidad",
        element: <InconformidadPage/>,
      },
      {
        label: "Evaluación",
        element: <Evaluar/>,
      },
      {
        label: "Reportes",
        element: <p>Reportes</p>,
      },
      {
        label: "Vaciados Excel",
        element: <p>Vaciados Excel</p>,
      },
      {
        label: "Generar inconformidad",
        element: <p>Generar inconformidad</p>,
      },
      {
        label: "Administración",
        element: <LlenarSolicitudPage />,
      },
    ],
    {
      selectedValue: "Desempeño docente",
      isVertical: false,
    }
  );

  console.log("Token:", accessToken);
  console.log("User Info:", userInfo);
  return (
    <div className={styles.jefe_departamento_page_container}>
      <Header></Header>
      {/* <div>JefeDepartamentoPage</div> */}

      <Menu menu={menu} />

      {/* <SplitView
        sidebarContent={
          <>
            <Menu menu={menu} />
          </>
        }
        mainContent={menu.element}
      /> */}
      {menu.element}

      {/* {data.map((item) => (
        <div key={item.id}>
          {item.nombre} {item.apellido}
        </div>
      ))} */}
      <Footer></Footer>
    </div>
  );
};
