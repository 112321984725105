import styles from "./requisitos.module.css";

export const Requisitos = () => {
  return (
    <>
      <div className={styles.requisitos_container}>
        <h2>Requisitos de participacion</h2>
        <div>
          <label htmlFor="">
            <strong>I. </strong>Ser parte de la carrera o técnico académico de
            tiempo completo con categoria de titular o asociado en cualquiera de
            sus niveles
          </label>
          <input className={styles.check} type="checkbox" />
        </div>
        <div>
          <label htmlFor="">
            <strong>II. </strong>Haber impartido docencia en la Universidad de
            Guadalajara, en el ciclo 2023A al menos 4 horas-semana-mes y en el
            calendario 2023B, al menos 8, en cursos y haber cumplido al menos el
            90% de las asistencias durante el periodo a evaluar
          </label>
          <input type="checkbox" />
        </div>
        <div>
          <label htmlFor="">
            <strong>III. </strong>Estar cubriendo actualmente una carga horaria
            de docencia curricular de al menos 8 horas-semana-mes en el ciclo
            escolar 2024A
          </label>
          <input type="checkbox" />
        </div>
        <div>
          <label htmlFor="">
            <strong>IV. </strong>Tener registrado su plan de trabajo anual en el
            deparetamento de adscripcion que inicia en enero del 2024, en el
            formato establecido para este fin, con el nombre y firma del jefe de
            deparetamento, Director de escuela o de sistema y el sello
            respectivo
          </label>
          <input type="checkbox" />
        </div>
        <div>
          <label htmlFor="">
            <strong>V. </strong>Haber cumplido en su totalidad con el plan de
            trabajo que presentó en la promocion anterior de este programa, si
            fue beneficiado
          </label>
          <input type="checkbox" />
        </div>
        <div>
          <label htmlFor="">
            <strong>VI. </strong>Contar por lo menos con el grado académico de
            maestro o el diploma de especialidad de al menos 2 años, este ultimo
            unicamente sera aplicable para el personal docente de las areas de
            Ciencias de la Salud
          </label>
          <input type="checkbox" />
        </div>
        <div>
          <label htmlFor="">
            <strong>VII. </strong>No trabajar mas de 8 horas semanales prestando
            servicios personales, subordinados o independientes en instituciones
            o empresas propias o ajenas. Para demostrar lo anterior, el
            aspirante deberá firmar una carta compromiso y de exclusividad en el
            formato establecido
          </label>
          <input type="checkbox" />
        </div>

        <div className={styles.guardar}>
          <label htmlFor="concursante1">Concursante</label>
          <input
            type="radio"
            id="concursante1"
            name="status"
            value="concursante"
          />

          <label htmlFor="concursante2">No concursante</label>
          <input
            type="radio"
            id="concursante2"
            name="status"
            value="noConcursante"
          />

          <button className={styles.button}>Guardar</button>
        </div>
      </div>
    </>
  );
};
