import React, { useState, useEffect } from "react";
import { login } from "../../../../features/authService";  // Import the login function
import logo_udg from "../../../../assets/images/UDG_white.png";
import styles from "./login.styles.module.css";
import fondo from "../../../../assets/images/login_fondo.jpg";
import { useNavigate } from 'react-router-dom';  // Assuming you're using react-router

export const LoginComponent = () => {
  const [usuario, setUsuario] = useState("");
  const [fechaNacimiento, setFechaNacimiento] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();  // Initialize useNavigate to handle redirection

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!usuario || !fechaNacimiento) {
      setError("Todos los campos son obligatorios.");
    } else {
      try {
        // Call the login function and get roles and permissions
        const { roles, rolesAsArray } = await login(usuario, fechaNacimiento);
        setError("");

        // Redirección basada en los roles del usuario
        if (rolesAsArray.includes('jefe_depto')) {
          navigate('/jefe_departamento');  // Redirigir a la página de jefe de departamento
        } 
        else if (rolesAsArray.includes('admin_gral')) {
          navigate('/administracion');  // Redirigir a la página de administración general
        } 
        else if (rolesAsArray.includes('admin_convocatoria')) { 
          navigate('/administracion');  
        } 
        else if (rolesAsArray.includes('Academico')) {
          navigate('/academico-dashboard');  // Redirigir a la página de académico
        } 
        else {
          navigate('/default-dashboard');  // Redirigir a una página por defecto si no hay roles específicos
        }
      } catch (error) {
        setError("Login failed. Please check your credentials.");
      }
    }
  };

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        setError("");  // Clear the error after 3 seconds
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [error]);

  return (
    <div>
      <div className={styles.fondo}>
        <img src={fondo} alt="fondo" />
      </div>
      <div className={styles.main}>
        <p className={styles.text}>
          Bienvenido al <br />
          <span className={styles.fontxl}>
            Programa de Estímulos al Desempeño Docente 2024-2023
          </span>
        </p>
        <div className={styles.login_container}>
          <form onSubmit={handleSubmit}>
            <img src={logo_udg} alt="logo UDG" />
            <label htmlFor="usuario" className={styles.label}>
              USUARIO
            </label>
            <input
              id="usuario"
              type="text"
              className={styles.input}
              placeholder="Si eres académico, ingresa su código"
              value={usuario}
              onChange={(e) => setUsuario(e.target.value)}
            />
            <label htmlFor="fecha_nacimiento" className={styles.label}>
              FECHA DE NACIMIENTO
            </label>
            <input
              id="fecha_nacimiento"
              type="date"
              className={styles.input}
              value={fechaNacimiento}
              onChange={(e) => setFechaNacimiento(e.target.value)}
            />

            <button className={styles.button}>Continuar</button>

            {error && <p className={styles.error}>{error}</p>}

            <p className={styles.contacto}>
              Toda comunicación será por medio del correo <br />
              electrónico <strong>proesde@redudg.udg.mx</strong>
            </p>
          </form>
        </div>
      </div>
    </div>
  );
};
