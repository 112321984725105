// TablaMaterias.jsx
import React from "react";
import styles from "./TablaMaterias.module.css"; // Importa el módulo de CSS

//* Campos 'programa', 'crn', 'clave', 'asignatura', 'horasSemana', 'horasSemestre', 'constancia', 'validada'

//* 'constancia' es un link de descarga
//* 'validada' es un checkbox para marcar si la constancia fue validada por el jefe de departamento
const TablaMaterias = () => {
  // Datos de ejemplo para la tabla de materias
  const materias = [
    {
      programa: "Ingeniería en Sistemas Computacionales",
      crn: "123456",
      clave: "ISC-1234",
      asignatura: "Fundamentos de programación",
      horasSemana: 4,
      horasSemestre: 64,
      constancia: "https://www.google.com",
      validada: false,
    },

    {
      programa: "Ingeniería en Sistemas Computacionales",
      crn: "123456",
      clave: "ISC-1234",
      asignatura: "Redes de computadoras",
      horasSemana: 4,
      horasSemestre: 64,
      constancia: "https://www.google.com",
      validada: true,
    },
    {
      programa: "Ingeniería en Sistemas Computacionales",
      crn: "123456",
      clave: "ISC-1234",
      asignatura: "Base de datos",
      horasSemana: 4,
      horasSemestre: 64,
      constancia: "https://www.google.com",
      validada: false,
    },
  ];

  return (
    <div className={styles.container}>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>PROGRAMA EDUCATIVO</th>
            <th>CRN</th>
            <th>CLAVE</th>
            <th>ASIGNATURA</th>
            <th>HORAS SEMANA</th>
            <th>HORAS SEMESTRE</th>
            <th>CONSTANCIA</th>
            <th>VALIDADA</th>
          </tr>
        </thead>
        <tbody>
          {materias.map((materia, index) => (
            <tr key={index}>
              <td>{materia.programa}</td>
              <td>{materia.crn}</td>
              <td>{materia.clave}</td>
              <td>{materia.asignatura}</td>
              <td>{materia.horasSemana}</td>
              <td>{materia.horasSemestre}</td>
              <td>
                <a
                  className={styles.link}
                  href={materia.constancia}
                  target="_blank"
                  rel="noreferrer"
                >
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth="0"
                    viewBox="0 0 24 24"
                    height="22px"
                    width="22px"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="Save_Down_2">
                      <g>
                        <path d="M18.437,20.948H5.563a2.372,2.372,0,0,1-2.5-2.21v-11a2.372,2.372,0,0,1,2.5-2.211h.462a.5.5,0,0,1,0,1H5.563a1.38,1.38,0,0,0-1.5,1.211v11a1.38,1.38,0,0,0,1.5,1.21H18.437a1.38,1.38,0,0,0,1.5-1.21v-11a1.38,1.38,0,0,0-1.5-1.211h-.462a.5.5,0,0,1,0-1h.462a2.372,2.372,0,0,1,2.5,2.211v11A2.372,2.372,0,0,1,18.437,20.948Z"></path>
                        <path d="M15.355,10.592l-3,3a.5.5,0,0,1-.35.15.508.508,0,0,1-.36-.15l-3-3a.5.5,0,0,1,.71-.71l2.14,2.139V3.552a.508.508,0,0,1,.5-.5.5.5,0,0,1,.5.5v8.49l2.15-2.16a.5.5,0,0,1,.71.71Z"></path>
                      </g>
                    </g>
                  </svg>
                  Descargar
                </a>
              </td>
              <td>
                <input
                  type="checkbox"
                  checked={materia.validada}
                  onChange={() => {}}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TablaMaterias;
