import create from "zustand";

export const useAuthStore = create((set) => ({
  accessToken: null,
  refreshToken: null,
  userInfo: null,

  setAccessToken: (token) => set({ accessToken: token }),
  setRefreshToken: (token) => set({ refreshToken: token }),
  setUserInfo: (info) => set({ userInfo: info }),

  logout: () => set({ accessToken: null, refreshToken: null, userInfo: null }),
}));
