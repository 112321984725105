import React, { useState } from "react";
import styles from "./Select.module.css";
// import { IoIosArrowDown } from "react-icons/io";

const IoIosArrowDown = ({ size, width, height }) => {
  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      viewBox="0 0 512 512"
      width={width || size || "1.1em"}
      height={height || size || "1.1em"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M256 294.1L383 167c9.4-9.4 24.6-9.4 33.9 0s9.3 24.6 0 34L273 345c-9.1 9.1-23.7 9.3-33.1.7L95 201.1c-4.7-4.7-7-10.9-7-17s2.3-12.3 7-17c9.4-9.4 24.6-9.4 33.9 0l127.1 127z"></path>
    </svg>
  );
};
/**
 * Componente Select
 * @param {Object} props
 * @param {Array} props.options - Opciones del select
 * @param {String} props.placeholder - Placeholder del select
 * @param {Object} props.form - form de react-hook-form
 * @param {String} props.name - Nombre del campo del form
 *
 * @example
 * const form = useForm();
 * const options = [{ value: 1, label: "Opción 1" }, { value: 2, label: "Opción 2" }];
 *
 * <Select options={options} form={form} name="campo" />
 *
 */
const Select = ({
  options,
  placeholder = "Seleccione una opción",
  form,
  name,
}) => {
  const [isVisibleOptions, setIsVisibleOptions] = useState(false);

  const handleSelectClick = () => {
    setIsVisibleOptions(!isVisibleOptions);
  };

  const handleOptionClick = (option) => {
    setIsVisibleOptions(false);
    form?.setValue(name, option.value);
  };
  const selectedOption = form?.watch(name);

  return (
    <div className={styles.selectContainer}>
      <div className={styles.selectBox} onClick={handleSelectClick}>
        {selectedOption || placeholder}
        {isVisibleOptions ? (
          <IoIosArrowDown  />
        ) : (
          <IoIosArrowDown  />
        )}
      </div>

      {isVisibleOptions && (
        <div className={styles.optionsContainer}>
          {options.map((option, index) => (
            <div
              key={index}
              className={styles.option}
              onClick={() => handleOptionClick(option)}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Select;
