import { LoginLayout } from "../components/Layout";


export const LoginPage = () => {
  return (
    <>
      <div>
        <LoginLayout />
      </div>
    </>
  );
};
